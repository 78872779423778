:root {
  --main: #5a447e;
  --secondary: #df9801;
  --gray: #636363;
  --dark-gray: #444444;
  --darker-gray: #383838;
  --light-gray: #9e9e9e;
  --lighter-gray: #c5c5c5;
  --dark: #282828;
  --white: #fff;
  --terminal-main-color: #33ff00;
  --terminal-warning-color: #FFB000;
  --terminal-second-color: #66ff66;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
