.Label {
  flex: 0 1 auto;
  color: var(--terminal-second-color);
  font-weight: 800;
  border-left: 10px solid var(--terminal-warning-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 5px;
  margin-right: 5px;
}
.Value {
  flex: 1 1 auto;
  color: var(--terminal-warning-color);
  font-weight: 400;
}