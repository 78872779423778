.searchBar {
  display: flex;
  position: fixed;
  background: transparent;
  justify-content: center;
  padding: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--dark);
  border-top: 1px dashed var(--terminal-second-color);
}

.searchBox {
  position: relative;
  margin: 1rem;
  display: flex;
  justify-content: center;
  width: auto;
  background: var(--dark);
}

.searchBox > input {
  flex: 0 0 80vw;
  padding: 10px;
  border-width: 0px;
  border-bottom: 1px inset var(--terminal-main-color);
  background: var(--dark);
  color: var(--terminal-main-color);
  border-radius: 0px;
}

.searchBox > button.resetSearch {
  flex: 0 1 10%;
  border-color: transparent;
  color: var(--light-gray);
  background: transparent;
}

.searchBox > input:focus {
  border-color: transparent;
  outline-color:transparent;
}

.searchBox > button {
  flex: 0 0 auto;
  padding: 5px 10px;
  border: 1px solid var(--light-gray);
  border-left-width: 0px;
  background: #dbdbdb;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}